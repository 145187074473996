const appConfig = {
  apiPrefix: "https://api.trabo.ai",
  googleAnalyticsMesurementId: null,
  // paypalPlanID: "P-5DL27127BU044544UMWYCESI", //test env removed to reduce price, we should also change script in index.html file
  paypalPlanID: "P-25A95532U9998794YM6FOPWI", //free product for testing
  authenticatedEntryPathUser: "/market",
  authenticatedEntryPathAdmin: "/instances",
  userUnAuthenticatedEntryPath: "/sign-in",
  adminUnAuthenticatedEntryPath: "/admin",
  tourPath: "/",
  enableMock: false,
  dataController: "Trabo Company",
};

export default appConfig;
