import ApiService from "./ApiService";

export async function apiGetMyBots() {
  return ApiService.fetchData({
    url: "/bots/me",
    method: "get",
  });
}

export async function apiSaveBot(data) {
  return ApiService.fetchData({
    url: "/bots",
    method: "post",
    data,
  });
}

export async function apiGetBotById(id) {
  return ApiService.fetchData({
    url: `/bots/${id}`,
    method: "get",
  });
}

export async function apiStartBot(data) {
  return ApiService.fetchData({
    url: `/bots/start`,
    method: "post",
    data,
  });
}

export async function apiStopBot(data) {
  return ApiService.fetchData({
    url: `/bots/stop`,
    method: "post",
    data,
  });
}

export async function apiTestBot(data) {
  return ApiService.fetchData({
    url: `/bots/testbot`,
    method: "post",
    data,
  });
}

export async function apiTestWebhook(botId, data) {
  return ApiService.fetchData({
    url: `/bots/${botId}/testwebhook`,
    method: "post",
    data,
  });
}

export async function apiDeleteBot(data) {
  return ApiService.fetchData({
    url: "/bots",
    method: "delete",
    data,
  });
}

export async function apiGetLogs(data) {
  return ApiService.fetchData({
    url: `/bots/logs?page_size=${data.size}&page=${data.page}`,
    method: "post",
    data,
  });
}

export async function apiGetVariables() {
  return ApiService.fetchData({
    url: "/variables",
    method: "get",
  });
}

export async function apiUpdateVariables(data) {
  return ApiService.fetchData({
    url: "/variables",
    method: "post",
    data,
  });
}

export async function apiGetBotStatistics(data) {
  return ApiService.fetchData({
    url: "/bots/statistics",
    method: "post",
    data,
  });
}

export async function apiAiGenerateBot(data) {
  return ApiService.fetchData({
    url: "/bots/generate",
    method: "post",
    data,
  });
}
